<template>
<div class="liff-course-album-page">
  <div class="page__body">
    <template v-if="mode === 'review'">
      <a-image-preview-group>
        <a-row :gutter="12">
          <a-col
            v-for="item in imagesData"
            class="mb-2"
            :span="12"
          >
            <a-image
              :src="item.uri"
              height="100%"
            />
          </a-col>
        </a-row>
      </a-image-preview-group>
    </template>

    <template v-else-if="mode === 'select'">
      <a-checkbox-group
        v-model:value="selectedImages"
        style="width: 100%"
      >
        <a-row :gutter="12">
          <a-col
            v-for="item in imagesData"
            class="mb-2"
            :span="12"
          >
            <div class="selected-image">
              <a-image
                @click="selectImage(item.uri)"
                :src="item.uri"
                :preview="false"
                height="100%"
              />
              <a-checkbox class="image-checkbox" :value="item.uri" />
            </div>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </template>
  </div>

  <div class="page__actions">
    <a-row>
      <a-col :span="8">
        <template v-if="mode == 'select'">
          <div class="mt-1" :style="{ color: '#757575' }">已選取 {{ selectedImages.length }} 張照片</div>
        </template>
      </a-col>
      <a-col :span="16" align="right">
        <template v-if="mode == 'review'">
          <a-button @click="mode='select'">分享照片</a-button>
        </template>
        <template v-else-if="mode == 'select'">
          <a-button type="text" @click="mode='review', selectedImages=[]">取消</a-button>
          <a-button class="ml-1" type="primary" @click="shareImages">分享給朋友</a-button>
        </template>
      </a-col>
    </a-row>
  </div>

  <template v-if="imagesData.length == 0">
    <a-empty class="mt-10" :image="simpleImage" description="沒有課堂照片" />
  </template>
</div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { message, Empty } from 'ant-design-vue'
import liff from '@line/liff/dist/lib'
import api from '@/apis'

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();

    /* Liff */
    const context = ref({});
    const initLiff = (async () => {
      try {
        await liff.init({ liffId: process.env.VUE_APP_LIFF_MY_COURSES });
        context.value = liff.getContext();
        console.log(context.value);
      } catch (err) {
        console.log(err.code, err.message);
      }
    });

    /* Mode */
    const mode = ref('review'); // review|select

    /* Data */
    const imagesData = ref([]);

    /* Images Select */
    const selectedImages = ref([]);

    const selectImage = (uri) => {
      if (!selectedImages.value.includes(uri)) {
        selectedImages.value.push(uri);
      } else {
        const index = selectedImages.value.indexOf(uri);
        selectedImages.value.splice(index, 1);
      }
    }

    const shareImages = (async () => {
      if (!liff.isApiAvailable('shareTargetPicker')) {
        message.error('您的裝置不支援分享照片功能');
        return
      }

      const LiffContents = [];
      selectedImages.value.forEach(uri => {
        LiffContents.push({
          type: 'image',
          originalContentUrl: uri,
          previewImageUrl: uri
        });
      });

      try {
        const res = await liff.shareTargetPicker(LiffContents);
        if (res) {
          message.success('分享照片給朋友成功');
        }
      } catch (error) {
        console.log(error);
        message.error('分享照片給朋友發生錯誤，請稍後再試');
      } finally {
        mode.value = 'review';
        selectedImages.value = [];
      }
    });

    /* Api */
    const getLessionsData = (async () => {
      imagesData.value = [];
      
      const response = await api.v1.lession.list({
        course_id: route.params.course_id
      });

      response.data.result.forEach(lession => {
        if (lession.images.length) {
          imagesData.value = imagesData.value.concat(lession.images);
        }
      });
    });

    /* Mounted */
    onMounted(async () => {
      await initLiff();
      await getLessionsData();
    });

    return {
      mode,

      /* Data */
      imagesData,

      /* Image Select */
      selectedImages,
      selectImage,
      shareImages,
    }
  },
  methods: {

  }
})
</script>

<style lang="scss">
.liff-course-album-page {
  .page__body {
    padding: 8px;
    height: calc(100vh - 57px - 52px - 46px);
    overflow: auto;
  }

  .page__actions {
    width: 100%;
    height: 46px;
    background: white;
    padding: 8px 12px;
  }
  
  /* Image Select */
  .selected-image {
    img {
      position: relative;
    }

    .image-checkbox {
      position: absolute;
      right: 16px;
      bottom: 8px;
    }
  }
}
</style>